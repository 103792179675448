export const RENNES_POLYGON = {
  "contour": {
    "type": "Polygon",
    "coordinates": [
      [
        [-1.739867, 48.131334],
        [-1.73942, 48.13203],
        [-1.739238, 48.132542],
        [-1.739034, 48.132845],
        [-1.738741, 48.133497],
        [-1.738638, 48.133769],
        [-1.738238, 48.133824],
        [-1.737886, 48.13504],
        [-1.737533, 48.134966],
        [-1.736507, 48.134823],
        [-1.73645, 48.135058],
        [-1.736205, 48.13578],
        [-1.735026, 48.135605],
        [-1.73492, 48.135785],
        [-1.73469, 48.136405],
        [-1.73441, 48.136408],
        [-1.73419, 48.136375],
        [-1.733934, 48.136283],
        [-1.733775, 48.136405],
        [-1.733569, 48.136698],
        [-1.733372, 48.136737],
        [-1.732917, 48.136614],
        [-1.732628, 48.136492],
        [-1.732221, 48.136416],
        [-1.73135, 48.136396],
        [-1.731225, 48.13626],
        [-1.730949, 48.136151],
        [-1.730543, 48.136032],
        [-1.730128, 48.136224],
        [-1.729959, 48.136273],
        [-1.729829, 48.136247],
        [-1.729537, 48.136119],
        [-1.729242, 48.135918],
        [-1.728875, 48.135788],
        [-1.728363, 48.13566],
        [-1.727868, 48.135394],
        [-1.727748, 48.13562],
        [-1.72764, 48.135942],
        [-1.727662, 48.136238],
        [-1.727653, 48.136761],
        [-1.727701, 48.137125],
        [-1.727819, 48.137321],
        [-1.727791, 48.137834],
        [-1.72796, 48.138113],
        [-1.72807, 48.138457],
        [-1.728112, 48.138988],
        [-1.728193, 48.139209],
        [-1.727808, 48.139258],
        [-1.727529, 48.139271],
        [-1.72773, 48.139494],
        [-1.728198, 48.139948],
        [-1.728363, 48.140071],
        [-1.72788, 48.141002],
        [-1.727489, 48.14178],
        [-1.727415, 48.141898],
        [-1.727376, 48.142095],
        [-1.727306, 48.142217],
        [-1.72708, 48.142452],
        [-1.726708, 48.142766],
        [-1.726049, 48.143431],
        [-1.725947, 48.143569],
        [-1.725507, 48.143876],
        [-1.725103, 48.144206],
        [-1.723728, 48.143691],
        [-1.72353, 48.143828],
        [-1.720843, 48.143048],
        [-1.720312, 48.142911],
        [-1.719766, 48.142751],
        [-1.719351, 48.142597],
        [-1.718978, 48.142709],
        [-1.718679, 48.142724],
        [-1.718247, 48.142697],
        [-1.717679, 48.14261],
        [-1.71743, 48.142623],
        [-1.717066, 48.142731],
        [-1.716523, 48.142833],
        [-1.716377, 48.142664],
        [-1.713775, 48.143381],
        [-1.713533, 48.143363],
        [-1.71307, 48.143365],
        [-1.712943, 48.142635],
        [-1.711635, 48.142769],
        [-1.711415, 48.142911],
        [-1.710825, 48.143098],
        [-1.710737, 48.143176],
        [-1.709414, 48.143627],
        [-1.70876, 48.143865],
        [-1.708739, 48.143912],
        [-1.708162, 48.144031],
        [-1.707364, 48.144117],
        [-1.706621, 48.144158],
        [-1.706177, 48.144169],
        [-1.705184, 48.144092],
        [-1.704806, 48.144036],
        [-1.704287, 48.143934],
        [-1.703815, 48.143912],
        [-1.702956, 48.14403],
        [-1.702621, 48.144092],
        [-1.702463, 48.143771],
        [-1.702145, 48.143069],
        [-1.701833, 48.142498],
        [-1.701561, 48.142113],
        [-1.70063, 48.140985],
        [-1.699883, 48.140216],
        [-1.699337, 48.139625],
        [-1.698763, 48.138942],
        [-1.698452, 48.138536],
        [-1.698092, 48.137981],
        [-1.697741, 48.137338],
        [-1.697463, 48.136936],
        [-1.697071, 48.136526],
        [-1.694946, 48.134595],
        [-1.694621, 48.134359],
        [-1.694039, 48.133981],
        [-1.693277, 48.133529],
        [-1.692588, 48.133044],
        [-1.692206, 48.132707],
        [-1.691508, 48.133165],
        [-1.690916, 48.133531],
        [-1.690332, 48.133801],
        [-1.689646, 48.134039],
        [-1.689311, 48.13423],
        [-1.689198, 48.134346],
        [-1.689039, 48.134595],
        [-1.6883, 48.134586],
        [-1.687193, 48.134507],
        [-1.685892, 48.134569],
        [-1.684558, 48.134667],
        [-1.684593, 48.134756],
        [-1.683534, 48.134876],
        [-1.683029, 48.134962],
        [-1.683058, 48.135088],
        [-1.682411, 48.135056],
        [-1.681731, 48.134935],
        [-1.681053, 48.134788],
        [-1.680512, 48.134729],
        [-1.679795, 48.134611],
        [-1.679421, 48.134869],
        [-1.678915, 48.135112],
        [-1.677871, 48.135541],
        [-1.677327, 48.135797],
        [-1.676732, 48.136143],
        [-1.676531, 48.13628],
        [-1.675961, 48.136782],
        [-1.67585, 48.136922],
        [-1.675662, 48.137253],
        [-1.675466, 48.137657],
        [-1.675086, 48.137501],
        [-1.674818, 48.137629],
        [-1.674534, 48.137376],
        [-1.674287, 48.137072],
        [-1.674143, 48.136843],
        [-1.674288, 48.136607],
        [-1.674108, 48.136556],
        [-1.673572, 48.136486],
        [-1.673449, 48.136453],
        [-1.672849, 48.136746],
        [-1.672523, 48.136874],
        [-1.672086, 48.136849],
        [-1.671828, 48.136867],
        [-1.671602, 48.137113],
        [-1.67116, 48.137658],
        [-1.671076, 48.137791],
        [-1.670688, 48.13773],
        [-1.670491, 48.137541],
        [-1.670222, 48.137441],
        [-1.669551, 48.137326],
        [-1.669425, 48.137529],
        [-1.669213, 48.137648],
        [-1.669214, 48.137827],
        [-1.669146, 48.137985],
        [-1.66898, 48.138214],
        [-1.668718, 48.138384],
        [-1.668777, 48.138537],
        [-1.668674, 48.138927],
        [-1.668606, 48.138989],
        [-1.668258, 48.13897],
        [-1.668101, 48.139023],
        [-1.667862, 48.139246],
        [-1.667668, 48.13952],
        [-1.667648, 48.139749],
        [-1.667673, 48.139922],
        [-1.667403, 48.140055],
        [-1.667107, 48.140154],
        [-1.666449, 48.140273],
        [-1.666416, 48.140956],
        [-1.66625, 48.140966],
        [-1.666239, 48.141345],
        [-1.666273, 48.14161],
        [-1.666363, 48.141637],
        [-1.666373, 48.141813],
        [-1.663751, 48.141771],
        [-1.663571, 48.141784],
        [-1.663179, 48.142278],
        [-1.662726, 48.143037],
        [-1.662695, 48.143036],
        [-1.661931, 48.142735],
        [-1.661527, 48.142557],
        [-1.661332, 48.14272],
        [-1.660823, 48.14276],
        [-1.660293, 48.142751],
        [-1.660079, 48.142704],
        [-1.65991, 48.142741],
        [-1.659857, 48.142859],
        [-1.659871, 48.143123],
        [-1.659552, 48.143113],
        [-1.658765, 48.143016],
        [-1.658122, 48.142961],
        [-1.657774, 48.142956],
        [-1.657137, 48.142971],
        [-1.657193, 48.142723],
        [-1.656639, 48.142652],
        [-1.656105, 48.142681],
        [-1.655817, 48.142672],
        [-1.655327, 48.142565],
        [-1.655301, 48.14248],
        [-1.654055, 48.142401],
        [-1.653542, 48.142322],
        [-1.653373, 48.142463],
        [-1.653295, 48.142474],
        [-1.653152, 48.141238],
        [-1.652995, 48.141151],
        [-1.652252, 48.141138],
        [-1.651375, 48.14114],
        [-1.651407, 48.140598],
        [-1.651383, 48.140499],
        [-1.65075, 48.140705],
        [-1.650068, 48.140951],
        [-1.649571, 48.141236],
        [-1.648974, 48.141701],
        [-1.648434, 48.1422],
        [-1.648077, 48.142763],
        [-1.647915, 48.143156],
        [-1.647686, 48.143464],
        [-1.647248, 48.143865],
        [-1.647108, 48.144173],
        [-1.647001, 48.144699],
        [-1.646968, 48.145046],
        [-1.646984, 48.1454],
        [-1.646663, 48.145411],
        [-1.646106, 48.145395],
        [-1.645815, 48.145371],
        [-1.645288, 48.145286],
        [-1.644552, 48.145148],
        [-1.643164, 48.14506],
        [-1.642631, 48.145049],
        [-1.642473, 48.145318],
        [-1.641945, 48.145895],
        [-1.641581, 48.14623],
        [-1.64117, 48.146562],
        [-1.640792, 48.146939],
        [-1.640527, 48.14731],
        [-1.641278, 48.148366],
        [-1.640637, 48.148688],
        [-1.640172, 48.148971],
        [-1.639166, 48.149281],
        [-1.639418, 48.14976],
        [-1.639837, 48.150739],
        [-1.639809, 48.150806],
        [-1.639594, 48.150975],
        [-1.639535, 48.151071],
        [-1.639619, 48.151249],
        [-1.639434, 48.15113],
        [-1.639261, 48.151113],
        [-1.638782, 48.151123],
        [-1.638746, 48.150896],
        [-1.638637, 48.150577],
        [-1.638658, 48.150521],
        [-1.638644, 48.15006],
        [-1.638668, 48.149817],
        [-1.638623, 48.149691],
        [-1.638246, 48.149775],
        [-1.637948, 48.149811],
        [-1.637709, 48.149771],
        [-1.637537, 48.149646],
        [-1.637467, 48.149431],
        [-1.637171, 48.149421],
        [-1.63633, 48.149474],
        [-1.635855, 48.149372],
        [-1.635357, 48.149451],
        [-1.635254, 48.149235],
        [-1.635144, 48.149262],
        [-1.634715, 48.149271],
        [-1.634494, 48.149308],
        [-1.634064, 48.149438],
        [-1.634189, 48.149737],
        [-1.634096, 48.149789],
        [-1.633668, 48.149925],
        [-1.632832, 48.150102],
        [-1.632734, 48.150198],
        [-1.632231, 48.151054],
        [-1.632096, 48.151361],
        [-1.631935, 48.151501],
        [-1.631543, 48.151762],
        [-1.631323, 48.152076],
        [-1.63122, 48.152466],
        [-1.631242, 48.152551],
        [-1.631033, 48.152985],
        [-1.630911, 48.153123],
        [-1.630606, 48.153393],
        [-1.630416, 48.153532],
        [-1.630107, 48.154126],
        [-1.630028, 48.154512],
        [-1.629862, 48.154806],
        [-1.629752, 48.154842],
        [-1.629433, 48.154855],
        [-1.628467, 48.154853],
        [-1.62686, 48.154805],
        [-1.626264, 48.154831],
        [-1.625742, 48.154899],
        [-1.624958, 48.154977],
        [-1.624732, 48.154989],
        [-1.624705, 48.15443],
        [-1.624618, 48.153019],
        [-1.624533, 48.152208],
        [-1.624455, 48.150788],
        [-1.624418, 48.149475],
        [-1.624438, 48.149358],
        [-1.624392, 48.149124],
        [-1.624359, 48.148669],
        [-1.62438, 48.14819],
        [-1.624474, 48.147556],
        [-1.624897, 48.146058],
        [-1.624853, 48.145883],
        [-1.624999, 48.145722],
        [-1.625194, 48.145668],
        [-1.625907, 48.145691],
        [-1.626558, 48.145756],
        [-1.627395, 48.145825],
        [-1.62783, 48.145812],
        [-1.628231, 48.145771],
        [-1.628327, 48.145792],
        [-1.628984, 48.145694],
        [-1.629202, 48.145619],
        [-1.629356, 48.145487],
        [-1.629451, 48.145341],
        [-1.629685, 48.144864],
        [-1.630402, 48.143474],
        [-1.630642, 48.142965],
        [-1.630967, 48.142313],
        [-1.631775, 48.140733],
        [-1.631908, 48.140571],
        [-1.632277, 48.140216],
        [-1.632531, 48.140014],
        [-1.633833, 48.138895],
        [-1.634267, 48.13842],
        [-1.634483, 48.138145],
        [-1.634867, 48.137696],
        [-1.63508, 48.137419],
        [-1.636069, 48.136257],
        [-1.636682, 48.13559],
        [-1.636597, 48.135335],
        [-1.636379, 48.13484],
        [-1.636214, 48.134612],
        [-1.635923, 48.134323],
        [-1.635592, 48.134092],
        [-1.635519, 48.134014],
        [-1.63548, 48.133824],
        [-1.635597, 48.133492],
        [-1.635618, 48.133297],
        [-1.635492, 48.133074],
        [-1.635198, 48.132753],
        [-1.634785, 48.132168],
        [-1.633894, 48.132477],
        [-1.633625, 48.132551],
        [-1.633084, 48.13267],
        [-1.632981, 48.132209],
        [-1.632885, 48.13195],
        [-1.632702, 48.131624],
        [-1.632525, 48.131394],
        [-1.632323, 48.131187],
        [-1.63072, 48.129717],
        [-1.630353, 48.129394],
        [-1.630097, 48.129131],
        [-1.629802, 48.128778],
        [-1.629627, 48.128518],
        [-1.629111, 48.127661],
        [-1.628815, 48.127047],
        [-1.628328, 48.12614],
        [-1.628006, 48.125464],
        [-1.627953, 48.125263],
        [-1.627968, 48.124966],
        [-1.628161, 48.124378],
        [-1.628491, 48.12369],
        [-1.62856, 48.123356],
        [-1.628543, 48.122926],
        [-1.628566, 48.122551],
        [-1.628659, 48.122285],
        [-1.628759, 48.122154],
        [-1.629009, 48.121916],
        [-1.629289, 48.121703],
        [-1.62947, 48.121613],
        [-1.630033, 48.121386],
        [-1.63029, 48.121251],
        [-1.630824, 48.120877],
        [-1.63108, 48.120644],
        [-1.631289, 48.120377],
        [-1.631453, 48.120064],
        [-1.631542, 48.119828],
        [-1.631617, 48.119168],
        [-1.631716, 48.118694],
        [-1.631846, 48.118387],
        [-1.631949, 48.118226],
        [-1.632155, 48.117744],
        [-1.632452, 48.117195],
        [-1.632608, 48.116846],
        [-1.633228, 48.11563],
        [-1.633398, 48.115262],
        [-1.63359, 48.114906],
        [-1.633846, 48.11428],
        [-1.633904, 48.114076],
        [-1.634069, 48.114083],
        [-1.63618, 48.11405],
        [-1.636087, 48.113385],
        [-1.636003, 48.113331],
        [-1.635345, 48.112762],
        [-1.635135, 48.112336],
        [-1.634809, 48.111829],
        [-1.634445, 48.111443],
        [-1.633992, 48.111087],
        [-1.633518, 48.110799],
        [-1.633383, 48.110697],
        [-1.633391, 48.110613],
        [-1.633551, 48.110465],
        [-1.633876, 48.110005],
        [-1.633949, 48.109784],
        [-1.633971, 48.109513],
        [-1.633958, 48.109043],
        [-1.633911, 48.108799],
        [-1.634019, 48.108658],
        [-1.634042, 48.107841],
        [-1.634046, 48.106601],
        [-1.634959, 48.10639],
        [-1.634667, 48.105442],
        [-1.634352, 48.105468],
        [-1.633223, 48.103815],
        [-1.633099, 48.103811],
        [-1.632804, 48.102822],
        [-1.63236, 48.102459],
        [-1.632046, 48.102558],
        [-1.631416, 48.102066],
        [-1.630083, 48.101536],
        [-1.629871, 48.101649],
        [-1.629736, 48.101601],
        [-1.629463, 48.101439],
        [-1.629301, 48.101383],
        [-1.629098, 48.101373],
        [-1.628599, 48.101391],
        [-1.62775, 48.101078],
        [-1.627203, 48.100677],
        [-1.626912, 48.100577],
        [-1.625683, 48.100313],
        [-1.625262, 48.100117],
        [-1.624534, 48.099933],
        [-1.625009, 48.099306],
        [-1.625264, 48.099323],
        [-1.625446, 48.098865],
        [-1.625607, 48.09852],
        [-1.625975, 48.097929],
        [-1.626024, 48.0974],
        [-1.626108, 48.097252],
        [-1.626065, 48.097115],
        [-1.626057, 48.096722],
        [-1.625998, 48.096564],
        [-1.625916, 48.095277],
        [-1.625926, 48.09504],
        [-1.626273, 48.094773],
        [-1.626171, 48.09465],
        [-1.626447, 48.093945],
        [-1.62679, 48.092738],
        [-1.626915, 48.092177],
        [-1.62694, 48.091984],
        [-1.626882, 48.091539],
        [-1.626811, 48.091514],
        [-1.626883, 48.091164],
        [-1.627023, 48.090944],
        [-1.627093, 48.090749],
        [-1.627316, 48.090371],
        [-1.627299, 48.09025],
        [-1.627612, 48.089556],
        [-1.627224, 48.089422],
        [-1.627358, 48.089214],
        [-1.627708, 48.089321],
        [-1.627796, 48.089314],
        [-1.628403, 48.088878],
        [-1.628454, 48.088748],
        [-1.628367, 48.088482],
        [-1.628691, 48.088242],
        [-1.629023, 48.088133],
        [-1.629134, 48.088014],
        [-1.629274, 48.087943],
        [-1.629504, 48.0877],
        [-1.629992, 48.087432],
        [-1.630939, 48.087032],
        [-1.631336, 48.086984],
        [-1.631571, 48.087013],
        [-1.632758, 48.087249],
        [-1.633093, 48.08726],
        [-1.633246, 48.087199],
        [-1.633692, 48.087129],
        [-1.634135, 48.086981],
        [-1.634739, 48.086673],
        [-1.635321, 48.086462],
        [-1.635493, 48.086383],
        [-1.635038, 48.08563],
        [-1.635266, 48.08562],
        [-1.635237, 48.085289],
        [-1.635264, 48.084746],
        [-1.635336, 48.084752],
        [-1.635383, 48.084199],
        [-1.63572, 48.084236],
        [-1.635844, 48.084181],
        [-1.635985, 48.084048],
        [-1.636328, 48.084216],
        [-1.636613, 48.084245],
        [-1.636998, 48.084392],
        [-1.637259, 48.084323],
        [-1.637919, 48.084386],
        [-1.637949, 48.084029],
        [-1.638137, 48.083766],
        [-1.637899, 48.083506],
        [-1.638155, 48.082797],
        [-1.638719, 48.082833],
        [-1.639209, 48.082736],
        [-1.639475, 48.082772],
        [-1.640179, 48.082744],
        [-1.640362, 48.08262],
        [-1.640601, 48.082369],
        [-1.640995, 48.082349],
        [-1.641253, 48.082275],
        [-1.641211, 48.0821],
        [-1.641098, 48.082055],
        [-1.640892, 48.08185],
        [-1.640868, 48.081589],
        [-1.641106, 48.081551],
        [-1.641534, 48.081652],
        [-1.642359, 48.081585],
        [-1.642512, 48.081614],
        [-1.642791, 48.081725],
        [-1.643458, 48.081588],
        [-1.643622, 48.081582],
        [-1.643968, 48.081098],
        [-1.644065, 48.081033],
        [-1.644526, 48.080831],
        [-1.64459, 48.080703],
        [-1.644196, 48.080581],
        [-1.644561, 48.08028],
        [-1.645018, 48.080122],
        [-1.645352, 48.079959],
        [-1.645548, 48.079925],
        [-1.645795, 48.07994],
        [-1.646292, 48.080033],
        [-1.646765, 48.079906],
        [-1.647244, 48.079723],
        [-1.647771, 48.079982],
        [-1.648085, 48.079913],
        [-1.648273, 48.07991],
        [-1.64882, 48.08002],
        [-1.648992, 48.080016],
        [-1.649358, 48.079916],
        [-1.649654, 48.079879],
        [-1.649965, 48.079857],
        [-1.650284, 48.079889],
        [-1.650602, 48.079892],
        [-1.651015, 48.079923],
        [-1.651122, 48.080252],
        [-1.65127, 48.080519],
        [-1.651353, 48.080559],
        [-1.651705, 48.080584],
        [-1.651877, 48.080716],
        [-1.651942, 48.080939],
        [-1.652106, 48.080993],
        [-1.652334, 48.080748],
        [-1.652658, 48.080493],
        [-1.653083, 48.080708],
        [-1.652957, 48.081169],
        [-1.653001, 48.081656],
        [-1.653205, 48.081758],
        [-1.653313, 48.08186],
        [-1.653508, 48.081915],
        [-1.654388, 48.081831],
        [-1.654664, 48.081831],
        [-1.655281, 48.08179],
        [-1.655583, 48.081668],
        [-1.655875, 48.081599],
        [-1.655881, 48.081457],
        [-1.657023, 48.081307],
        [-1.657664, 48.081205],
        [-1.658043, 48.08123],
        [-1.658183, 48.081341],
        [-1.658163, 48.081757],
        [-1.658293, 48.081848],
        [-1.658317, 48.082039],
        [-1.658112, 48.08212],
        [-1.658175, 48.082426],
        [-1.658511, 48.082454],
        [-1.658776, 48.082577],
        [-1.659075, 48.08265],
        [-1.659646, 48.082573],
        [-1.659544, 48.082768],
        [-1.659637, 48.082902],
        [-1.660392, 48.082731],
        [-1.660471, 48.082545],
        [-1.660574, 48.082523],
        [-1.660756, 48.082588],
        [-1.661005, 48.082498],
        [-1.661429, 48.08292],
        [-1.66259, 48.08282],
        [-1.662901, 48.08264],
        [-1.66306, 48.082446],
        [-1.663029, 48.082293],
        [-1.662375, 48.081294],
        [-1.662249, 48.081059],
        [-1.662255, 48.080524],
        [-1.662198, 48.080462],
        [-1.661997, 48.079666],
        [-1.661711, 48.078986],
        [-1.661314, 48.078412],
        [-1.661155, 48.078106],
        [-1.662534, 48.07781],
        [-1.663261, 48.077692],
        [-1.665536, 48.077242],
        [-1.666063, 48.077159],
        [-1.666696, 48.077106],
        [-1.668357, 48.077023],
        [-1.670702, 48.0769],
        [-1.671404, 48.07688],
        [-1.671341, 48.077166],
        [-1.672469, 48.077237],
        [-1.672805, 48.077232],
        [-1.672803, 48.077308],
        [-1.673329, 48.077421],
        [-1.673784, 48.077587],
        [-1.674386, 48.077852],
        [-1.67449, 48.077855],
        [-1.674847, 48.077706],
        [-1.675098, 48.077669],
        [-1.675467, 48.077675],
        [-1.675713, 48.077617],
        [-1.676242, 48.077542],
        [-1.67685, 48.077437],
        [-1.67737, 48.077368],
        [-1.677958, 48.077322],
        [-1.678808, 48.077275],
        [-1.6796, 48.081088],
        [-1.679518, 48.081182],
        [-1.679661, 48.081308],
        [-1.679828, 48.08134],
        [-1.679931, 48.081424],
        [-1.680204, 48.081787],
        [-1.680276, 48.082079],
        [-1.680669, 48.082019],
        [-1.680796, 48.082022],
        [-1.681166, 48.08195],
        [-1.681405, 48.081837],
        [-1.681638, 48.081682],
        [-1.681969, 48.081392],
        [-1.681996, 48.081207],
        [-1.682291, 48.081125],
        [-1.68252, 48.081004],
        [-1.68272, 48.080938],
        [-1.683856, 48.080706],
        [-1.684088, 48.080368],
        [-1.684468, 48.08026],
        [-1.684816, 48.080243],
        [-1.685382, 48.080277],
        [-1.685596, 48.080344],
        [-1.685808, 48.080488],
        [-1.686048, 48.080586],
        [-1.686487, 48.080563],
        [-1.687286, 48.080479],
        [-1.687576, 48.080508],
        [-1.687786, 48.080585],
        [-1.688039, 48.080586],
        [-1.688316, 48.080507],
        [-1.688553, 48.080499],
        [-1.689875, 48.080493],
        [-1.690114, 48.080762],
        [-1.691005, 48.081386],
        [-1.691342, 48.081481],
        [-1.691423, 48.081341],
        [-1.691676, 48.081233],
        [-1.69207, 48.08114],
        [-1.692379, 48.081267],
        [-1.692746, 48.081339],
        [-1.693157, 48.081376],
        [-1.693423, 48.081358],
        [-1.693786, 48.081375],
        [-1.694023, 48.081426],
        [-1.694162, 48.080774],
        [-1.694911, 48.080677],
        [-1.695503, 48.080531],
        [-1.696064, 48.080593],
        [-1.696394, 48.080599],
        [-1.696865, 48.080512],
        [-1.697177, 48.08048],
        [-1.699092, 48.080482],
        [-1.699516, 48.080624],
        [-1.701182, 48.081159],
        [-1.70124, 48.081285],
        [-1.701441, 48.081527],
        [-1.701665, 48.081623],
        [-1.701981, 48.081606],
        [-1.702551, 48.081486],
        [-1.702761, 48.081401],
        [-1.702869, 48.08142],
        [-1.703357, 48.081791],
        [-1.703519, 48.081889],
        [-1.703706, 48.08187],
        [-1.703824, 48.081804],
        [-1.703925, 48.081897],
        [-1.703716, 48.082196],
        [-1.70365, 48.082358],
        [-1.703658, 48.082478],
        [-1.703742, 48.082589],
        [-1.703745, 48.082699],
        [-1.703367, 48.083453],
        [-1.703272, 48.083692],
        [-1.70292, 48.084145],
        [-1.702469, 48.084602],
        [-1.699864, 48.08569],
        [-1.698306, 48.086536],
        [-1.698178, 48.086663],
        [-1.698022, 48.086714],
        [-1.696745, 48.086891],
        [-1.696628, 48.086963],
        [-1.696018, 48.086836],
        [-1.695859, 48.086766],
        [-1.695778, 48.086669],
        [-1.695782, 48.086474],
        [-1.695064, 48.086431],
        [-1.694473, 48.086462],
        [-1.69436, 48.086704],
        [-1.693632, 48.086743],
        [-1.692957, 48.086806],
        [-1.692925, 48.08765],
        [-1.693011, 48.088506],
        [-1.692774, 48.089297],
        [-1.692686, 48.089435],
        [-1.69258, 48.089811],
        [-1.692639, 48.089919],
        [-1.692762, 48.090311],
        [-1.691016, 48.093227],
        [-1.690635, 48.093889],
        [-1.690387, 48.094411],
        [-1.689531, 48.096124],
        [-1.689318, 48.096497],
        [-1.689749, 48.096578],
        [-1.68974, 48.096643],
        [-1.69036, 48.096707],
        [-1.691215, 48.096814],
        [-1.6916, 48.096691],
        [-1.691787, 48.096922],
        [-1.692268, 48.096792],
        [-1.692327, 48.09688],
        [-1.692848, 48.096867],
        [-1.693625, 48.097178],
        [-1.693875, 48.097266],
        [-1.693941, 48.097175],
        [-1.694451, 48.097342],
        [-1.69454, 48.097323],
        [-1.695847, 48.097223],
        [-1.696978, 48.097068],
        [-1.69775, 48.096983],
        [-1.697847, 48.097021],
        [-1.698447, 48.096957],
        [-1.698475, 48.096845],
        [-1.69888, 48.096754],
        [-1.700237, 48.096477],
        [-1.70117, 48.096267],
        [-1.706026, 48.095621],
        [-1.707061, 48.095437],
        [-1.710188, 48.094856],
        [-1.710708, 48.094797],
        [-1.711156, 48.094623],
        [-1.713342, 48.09371],
        [-1.714568, 48.092996],
        [-1.714734, 48.092866],
        [-1.715128, 48.092659],
        [-1.715704, 48.092489],
        [-1.716603, 48.092201],
        [-1.717048, 48.092046],
        [-1.717751, 48.091843],
        [-1.718973, 48.091445],
        [-1.7197, 48.091117],
        [-1.72022, 48.090819],
        [-1.721246, 48.090151],
        [-1.722691, 48.089158],
        [-1.723363, 48.088744],
        [-1.724219, 48.088231],
        [-1.724609, 48.087895],
        [-1.725179, 48.087241],
        [-1.725406, 48.086926],
        [-1.725453, 48.086769],
        [-1.725438, 48.086631],
        [-1.725651, 48.086419],
        [-1.726028, 48.086633],
        [-1.726199, 48.086695],
        [-1.726504, 48.086705],
        [-1.726767, 48.086742],
        [-1.727182, 48.086924],
        [-1.727605, 48.086521],
        [-1.728085, 48.086283],
        [-1.728954, 48.085908],
        [-1.728743, 48.085698],
        [-1.728174, 48.085002],
        [-1.728122, 48.084919],
        [-1.728421, 48.084822],
        [-1.729005, 48.084562],
        [-1.729085, 48.084606],
        [-1.729634, 48.084425],
        [-1.729895, 48.084364],
        [-1.73071, 48.084113],
        [-1.731215, 48.083937],
        [-1.731113, 48.083837],
        [-1.731788, 48.083657],
        [-1.733051, 48.083349],
        [-1.733405, 48.083235],
        [-1.733789, 48.083085],
        [-1.734066, 48.083002],
        [-1.735394, 48.082641],
        [-1.736153, 48.082359],
        [-1.736564, 48.082139],
        [-1.737012, 48.081946],
        [-1.738122, 48.081555],
        [-1.738555, 48.081356],
        [-1.73917, 48.081101],
        [-1.739603, 48.080949],
        [-1.740094, 48.080738],
        [-1.740661, 48.08042],
        [-1.741303, 48.080087],
        [-1.741569, 48.079969],
        [-1.741976, 48.080175],
        [-1.742583, 48.080381],
        [-1.742859, 48.080524],
        [-1.74388, 48.080925],
        [-1.745518, 48.081001],
        [-1.747272, 48.081073],
        [-1.748108, 48.081096],
        [-1.748594, 48.081159],
        [-1.750328, 48.081507],
        [-1.751609, 48.081787],
        [-1.751499, 48.082159],
        [-1.751275, 48.082524],
        [-1.751021, 48.082999],
        [-1.750747, 48.083573],
        [-1.750696, 48.083874],
        [-1.75029, 48.084118],
        [-1.750075, 48.084225],
        [-1.749949, 48.084367],
        [-1.749782, 48.08485],
        [-1.749768, 48.085002],
        [-1.74982, 48.085235],
        [-1.750075, 48.08546],
        [-1.750843, 48.085694],
        [-1.751307, 48.085928],
        [-1.751492, 48.085999],
        [-1.751616, 48.086211],
        [-1.752081, 48.086725],
        [-1.752301, 48.087007],
        [-1.752528, 48.087463],
        [-1.752518, 48.08803],
        [-1.752388, 48.088421],
        [-1.75228, 48.089088],
        [-1.752077, 48.08979],
        [-1.751894, 48.090539],
        [-1.751753, 48.091359],
        [-1.751573, 48.091702],
        [-1.751198, 48.092066],
        [-1.750568, 48.092511],
        [-1.749375, 48.093128],
        [-1.749087, 48.093361],
        [-1.748741, 48.093597],
        [-1.74827, 48.093971],
        [-1.747422, 48.094508],
        [-1.747149, 48.094701],
        [-1.746905, 48.094967],
        [-1.74669, 48.095248],
        [-1.746648, 48.095413],
        [-1.746524, 48.095657],
        [-1.746549, 48.09612],
        [-1.746449, 48.096363],
        [-1.746345, 48.096495],
        [-1.745964, 48.09688],
        [-1.745475, 48.097243],
        [-1.745289, 48.097497],
        [-1.744598, 48.098112],
        [-1.743997, 48.098893],
        [-1.743638, 48.099245],
        [-1.743327, 48.099494],
        [-1.743263, 48.09958],
        [-1.742368, 48.099354],
        [-1.742115, 48.09932],
        [-1.742024, 48.099374],
        [-1.741965, 48.099764],
        [-1.742077, 48.100212],
        [-1.742132, 48.100321],
        [-1.74169, 48.100582],
        [-1.740964, 48.100946],
        [-1.740398, 48.101105],
        [-1.73942, 48.101412],
        [-1.739226, 48.101495],
        [-1.738813, 48.101624],
        [-1.73793, 48.101877],
        [-1.737438, 48.102],
        [-1.736795, 48.10219],
        [-1.734116, 48.103833],
        [-1.734574, 48.105141],
        [-1.734919, 48.105256],
        [-1.735209, 48.105173],
        [-1.735334, 48.105373],
        [-1.736119, 48.105473],
        [-1.736242, 48.105764],
        [-1.736147, 48.105792],
        [-1.736344, 48.106213],
        [-1.736162, 48.106337],
        [-1.736181, 48.106406],
        [-1.736631, 48.107116],
        [-1.736313, 48.107618],
        [-1.736135, 48.107678],
        [-1.736055, 48.107778],
        [-1.736065, 48.107987],
        [-1.736283, 48.108243],
        [-1.736215, 48.108361],
        [-1.736234, 48.108535],
        [-1.736465, 48.109434],
        [-1.736585, 48.109855],
        [-1.736584, 48.109931],
        [-1.735848, 48.110008],
        [-1.731653, 48.110431],
        [-1.731185, 48.110494],
        [-1.729589, 48.110649],
        [-1.72861, 48.110737],
        [-1.728422, 48.11087],
        [-1.728209, 48.111174],
        [-1.72827, 48.111385],
        [-1.728465, 48.111515],
        [-1.728712, 48.111596],
        [-1.728929, 48.111729],
        [-1.729045, 48.111863],
        [-1.72926, 48.112236],
        [-1.729553, 48.112662],
        [-1.729636, 48.112839],
        [-1.729712, 48.113104],
        [-1.73002, 48.113746],
        [-1.730176, 48.113979],
        [-1.730771, 48.114628],
        [-1.731196, 48.114996],
        [-1.731078, 48.115188],
        [-1.730809, 48.115371],
        [-1.729946, 48.115817],
        [-1.728767, 48.116512],
        [-1.728157, 48.116831],
        [-1.727777, 48.11695],
        [-1.727048, 48.117086],
        [-1.726764, 48.117198],
        [-1.725876, 48.117016],
        [-1.725753, 48.117205],
        [-1.725414, 48.117809],
        [-1.72503, 48.118338],
        [-1.72483, 48.118663],
        [-1.724731, 48.118872],
        [-1.724595, 48.119323],
        [-1.724566, 48.119484],
        [-1.724545, 48.120005],
        [-1.724373, 48.120952],
        [-1.724099, 48.121529],
        [-1.723951, 48.121655],
        [-1.724425, 48.121957],
        [-1.724773, 48.122103],
        [-1.725501, 48.122318],
        [-1.72632, 48.122521],
        [-1.727238, 48.122718],
        [-1.729306, 48.123131],
        [-1.729739, 48.123245],
        [-1.730095, 48.123377],
        [-1.729046, 48.124536],
        [-1.728536, 48.125164],
        [-1.728379, 48.125382],
        [-1.728775, 48.125566],
        [-1.729176, 48.125598],
        [-1.7292, 48.125829],
        [-1.729338, 48.125942],
        [-1.729557, 48.126031],
        [-1.729837, 48.126249],
        [-1.730146, 48.12641],
        [-1.730347, 48.126476],
        [-1.730584, 48.126499],
        [-1.730622, 48.126692],
        [-1.73083, 48.1268],
        [-1.731406, 48.127009],
        [-1.73221, 48.127315],
        [-1.733418, 48.127629],
        [-1.735337, 48.128501],
        [-1.735547, 48.128577],
        [-1.735459, 48.128809],
        [-1.735929, 48.12902],
        [-1.73645, 48.129317],
        [-1.736824, 48.129453],
        [-1.736945, 48.129463],
        [-1.737444, 48.12986],
        [-1.737694, 48.130115],
        [-1.738078, 48.130301],
        [-1.738577, 48.13051],
        [-1.739293, 48.130765],
        [-1.739164, 48.131033],
        [-1.739867, 48.131334],
      ],
    ],
  },
};
